import React, { Fragment, useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import SeoComp from "../../../components/SeoComp"
import Testimonials from "../../../components/Layout/Testimonials"
import Footer from "../../../components/Layout/Footer"
import { withStoreContext } from "../../../context/StoreContext"

import {
  Select,
  ProductEstimateShippingBox,
  ProductEstimateShippingIcon,
  ProductEstimateShippingText,
  LyneComboBoxTagWrapper,
  LyneComboBottomSection,
  LyneComboLeftSection,
  LyneComboBox,
  LyneCheckImage,
  MatelasComboWrapper,
  MatelasComboBoxImage,
  DimensionText,
  EcoParticipationText,
  AddToCartWrapper,
  MatelasButtonWrapper,
  LyneupContainer,
  ProductShopSection,
  LyneShopImageSection,
  MatelasShopInfoSection,
  MatelasShopBox,
  LyneSizeSelectedText,
  EcoPartToolTip,
  ExclusifComboBoxTag,
  ExclusifComboBoxTitle,
  ExclusifProductStrikePrice,
  ExclusifProductDiscountPrice,
  ExclusifAddToCart,
} from "../../../components/Products/styled"
import MatelasProductTopSection from "../../../components/Products/MatelasProductTopSection"
import LyneupPageSectionList from "../../../components/Products/LyneupPageSectionList"
import SoulagementSection from "../../../components/Products/SoulagementSection"
import SoulagerSection from "../../../components/Products/SoulagerSection"
import ConfortSection from "../../../components/Products/ConfortSection"
import ProductSainSection from "../../../components/Products/ProductSainSection"
import CompositionSection from "../../../components/Products/CompositionSection"
import MatelasReviewSection from "../../../components/Products/MatelasReviewSection"
import ProductFaqSection from "../../../components/Products/ProductFaqSection"
import CrossProductSection from "../../../components/Products/CrossProductSection"
import MatelasAlmaSection from "../../../components/Products/MatelasAlmaSection"
import MatelasStickyAddToCart from "../../../components/Products/MatelasStickyAddToCart"
import { getPrice, navigateToCart } from "../../../utils/navigateToCart"
import { useLocation } from "@reach/router"
import { isBrowser } from "../../../context/ApolloContext"
import {
  addToCartScript,
  productGtmEvent,
} from "../../../utils/additionalScriptHelpers"
import { cartEstimateDeliveryText } from "../../../utils/cartHelpers"
import MatelasMiniCart from "../../../components/MatelasMiniCart"
import MatelasSommierSection from "../../../components/MatelasSommierSection"
const $ = isBrowser ? require("jquery") : {}
import MatelasImageSection from "../../../components/Products/MatelasImageSection"
import LyneImageSlider from "../../../components/Products/LyneImageSlider"
import LyneTestimonialSection from "../../../components/Products/LyneTestimonialSection"
import MatelasProductType from "../../../components/Products/MatelasProductType"
import MatelasComparisonSection from "../../../components/Products/MatelasComparisonSection"
import TrustPilotReviewSection from "../../../components/Products/TrustPilotReviewSection"
import ReactTooltip from "react-tooltip"

const matelas = ({
  intl,
  data,
  data: { allShopifyProduct: product },
  storeContext,
  storeContext: { client, checkout, addVariantToCart },
}) => {
  const [selectedVariant, setselectedVariant] = useState(null)
  const [singleMattressVariant, setsingleMattressVariant] = useState(null)
  const [packMattressVariant, setpackMattressVariant] = useState(null)
  const [singleMattressData, setsingleMattressData] = useState(null)
  const [packMattressData, setpackMattressData] = useState(null)
  const [selectedVariantQuantity, setselectedVariantQuantity] = useState(1)
  const [selectOptions, setselectOptions] = useState({})
  const [selectOfferType, setSelectOfferType] = useState("Seul")
  const [selectProductType, setSelectProductType] = useState("hybride")
  const [productPrice, setproductPrice] = useState(649)
  const [Errors, setErrors] = useState({ exist: false, message: [] })
  const [activeFlag, setactiveFlag] = useState(false)
  const [ecoParticipationPrice, setecoParticipationPrice] = useState("")
  const [showMiniCart, setshowMiniCart] = useState(false)

  const setInitialParamValues = () => {
    const queryString = isBrowser && window.location.search
    const urlParams = new URLSearchParams(queryString)
    const sizeParam = urlParams.get("Size")
    const offerParam = urlParams.get("Offer")
    let productType = urlParams.get("Type")
    let options = { ...selectOptions }
    let product = ""

    let singleMattress = data.allShopifyProduct?.edges.find(
      item => item.node.handle === "matelas"
    )?.node
    let packMattress = data.allShopifyProduct?.edges.find(
      item => item.node.handle === "1-matelas-oreiller"
    )?.node

    if (productType === "mousse") {
      singleMattress = data.allShopifyProduct?.edges.find(
        item => item.node.handle === "matelas-mousse"
      )?.node

      packMattress = data.allShopifyProduct?.edges.find(
        item => item.node.handle === "1-matelas-mousse-oreiller"
      )?.node
    }

    if (productType === "mousse") {
      setSelectProductType("mousse")
    } else {
      setSelectProductType("hybride")
    }

    if (offerParam === "Pack") {
      setSelectOfferType("Pack")
      product = packMattress
      ecoParticipation("Pack", sizeParam)
    } else {
      setSelectOfferType("Seul")
      product = singleMattress
      ecoParticipation("Seul", sizeParam)
    }

    if (sizeParam) {
      options.Size = sizeParam
    } else {
      options.Size = "80x200cm"
    }

    let paramString = ""
    paramString = paramString + "?Size=" + options.Size
    paramString = paramString + "&Type=" + selectProductType
    paramString = paramString + "&Offer=" + selectOfferType
    isBrowser && history.replaceState(null, null, paramString)

    if (Object.keys(options).length === 1) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        options
      )
      const singleMattressVariant =
        singleMattress?.variants.length > 0 &&
        client.product.helpers.variantForOptions(singleMattress, options)

      const packMattressVariant =
        packMattress?.variants.length > 0 &&
        client.product.helpers.variantForOptions(packMattress, options)

      setselectedVariant(selectedVariant)
      setsingleMattressVariant(singleMattressVariant)
      setpackMattressVariant(packMattressVariant)

      let productDiscountPrice = selectedVariant.price
      if (offerParam === "Pack") {
        productDiscountPrice = groupDiscountPrice()
      } else {
        productDiscountPrice = discountPrice()
      }
      setproductPrice(productDiscountPrice)

      productGtmEvent(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    }

    setselectOptions(options)
    setsingleMattressData(singleMattress)
    setpackMattressData(packMattress)
  }

  const handleParamStrings = (selectedOptions, productType, offerType) => {
    let paramString = ""

    Object.keys(selectedOptions).map(item => {
      if (paramString.length === 0) {
        if (item === "Size") {
          paramString = paramString + "?" + item + "=" + selectedOptions[item]
        }
      } else {
        if (item === "Size") {
          paramString = paramString + "&" + item + "=" + selectedOptions[item]
        }
      }
    })

    if (productType) {
      paramString = paramString + "&Type" + "=" + productType
    }
    if (offerType) {
      paramString = paramString + "&Offer" + "=" + offerType
    }

    isBrowser && history.replaceState(null, null, paramString)
  }

  const handleChangeSelectOptions = event => {
    const { target } = event
    let selectedOptions = { ...selectOptions }
    let product = ""
    let productType = selectProductType

    if (target.name === "Size") {
      selectedOptions[target.name] = target.value
    } else if (target.name === "Type") {
      productType = target.value
    }

    let singleMattress = data.allShopifyProduct?.edges.find(
      item => item.node.handle === "matelas"
    )?.node
    let packMattress = data.allShopifyProduct?.edges.find(
      item => item.node.handle === "1-matelas-oreiller"
    )?.node

    if (productType === "mousse") {
      singleMattress = data.allShopifyProduct?.edges.find(
        item => item.node.handle === "matelas-mousse"
      )?.node

      packMattress = data.allShopifyProduct?.edges.find(
        item => item.node.handle === "1-matelas-mousse-oreiller"
      )?.node
    }
    setsingleMattressData(singleMattress)
    setpackMattressData(packMattress)

    handleParamStrings(selectedOptions, productType, selectOfferType)
    setselectOptions(selectedOptions)

    if (selectOfferType === "Pack") {
      setSelectOfferType("Pack")
      product = packMattress
      ecoParticipation("Pack", selectedOptions["Size"])
    } else {
      setSelectOfferType("Seul")
      product = singleMattress
      ecoParticipation("Seul", selectedOptions["Size"])
    }

    if (Object.keys(selectedOptions).length === 1) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        selectedOptions
      )
      const singleMattressVariant = client.product.helpers.variantForOptions(
        singleMattress,
        selectedOptions
      )
      const packMattressVariant = client.product.helpers.variantForOptions(
        packMattress,
        selectedOptions
      )

      let productDiscountPrice = selectedVariant.price
      if (selectOfferType === "Pack") {
        productDiscountPrice = groupDiscountPrice()
      } else {
        productDiscountPrice = discountPrice()
      }

      productGtmEvent(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
      setselectedVariant(selectedVariant)
      setsingleMattressVariant(singleMattressVariant)
      setpackMattressVariant(packMattressVariant)
    }
  }

  const removeErros = option => {
    let error = { ...Errors }
    if (error.exist) {
      // if size exists
      if (option["Size"]) {
        let found = error.message.find(
          element =>
            element ===
            intl.formatMessage({
              id: "size_not_seleceted_error_lynefit",
            })
        )
        if (found) {
          let index = error.message.indexOf(found)
          error.message.splice(index, 1)
        }
      }
      if (error.message.length === 0) error.exist = false
      setErrors(error)
    }
  }

  const handleErrors = () => {
    let error = { ...Errors }
    if (!selectOptions["Size"] || selectOptions["Size"].length > 3) {
      error.exist = true
      let found = error.message.find(
        element =>
          element ===
          intl.formatMessage({ id: "size_not_seleceted_error_lynefit" })
      )
      if (!found)
        error.message = [
          ...error.message,
          intl.formatMessage({ id: "size_not_seleceted_error_lynefit" }),
        ]
    }

    removeErros(selectOptions)
    setErrors(error)
  }

  const handleAddTocart = async () => {
    if (selectedVariant) {
      addVariantToCart(
        selectedVariant.id,
        selectedVariantQuantity,
        null,
        checkout => {
          addToCartScript(
            selectOfferType === "Seul"
              ? singleMattressData.id
              : packMattressData.id,
            selectOfferType === "Seul"
              ? singleMattressData.title
              : packMattressData.title,
            selectedVariant,
            selectedVariantQuantity,
            checkout,
            intl.locale
          )
          if (
            selectOptions["Size"] != "90x190cm" &&
            selectOptions["Size"] != "140x190cm" &&
            selectOptions["Size"] != "160x200cm" &&
            selectOptions["Size"] != "180x200cm"
          ) {
            navigateToCart(intl.locale)
          } else {
            setshowMiniCart(true)
          }
        }
      )
    } else {
      handleErrors()
    }
  }

  const handleOfferType = offerType => {
    let price = ""
    let product = ""
    let selectedOptions = { ...selectOptions }

    setSelectOfferType(offerType)
    handleParamStrings(selectedOptions, selectProductType, offerType)

    if (offerType === "Pack") {
      price = groupDiscountPrice()
      product = packMattressData
    } else {
      price = discountPrice()
      product = singleMattressData
    }

    ecoParticipation(offerType, selectedOptions["Size"])

    setproductPrice(price)

    if (Object.keys(selectedOptions).length === 1) {
      const selectedVariant = client.product.helpers.variantForOptions(
        product,
        selectedOptions
      )
      const singleMattressVariant = client.product.helpers.variantForOptions(
        singleMattressData,
        selectedOptions
      )
      const packMattressVariant = client.product.helpers.variantForOptions(
        packMattressData,
        selectedOptions
      )
      setselectedVariant(selectedVariant)
      setsingleMattressVariant(singleMattressVariant)
      setpackMattressVariant(packMattressVariant)
    }
  }

  const handleProductType = productType => {
    setSelectProductType(productType)

    handleChangeSelectOptions({
      target: { name: "Type", value: productType },
    })
  }

  let discountPercentage = 0.3

  const singlePrice = () => {
    let singlePrice = Number(
      singleMattressVariant
        ? singleMattressVariant.price
        : singleMattressData?.priceRangeV2?.maxVariantPrice.amount
    )

    return getPrice(singlePrice)
  }

  const discountPrice = () => {
    let discountPrice = Number(
      singleMattressVariant
        ? singleMattressVariant.price
        : singleMattressData?.priceRangeV2?.maxVariantPrice.amount
    )

    discountPercentage = 0.3

    discountPrice =
      Number(discountPrice) - Number(discountPrice) * Number(discountPercentage)

    return getPrice(discountPrice)
  }

  const groupPrice = () => {
    let groupPrice = Number(
      packMattressVariant
        ? packMattressVariant.price
        : packMattressData?.priceRangeV2?.maxVariantPrice.amount
    )

    return getPrice(groupPrice)
  }

  const groupDiscountPrice = () => {
    let groupDiscountPrice = groupPrice()

    discountPercentage = 0.35

    groupDiscountPrice =
      Number(groupDiscountPrice) -
      Number(groupDiscountPrice) * Number(discountPercentage)

    return getPrice(groupDiscountPrice)
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }

    $(document).ready(function() {
      window.addEventListener("scroll", function(e) {
        if (!isOnScreen($("#add_to_cart")) && !isOnScreen($("#footer"))) {
          setactiveFlag(true)
        } else {
          setactiveFlag(false)
        }
      })
    })
  }

  const ecoParticipation = (type, size) => {
    if (type === "Pack") {
      if (size == "180x200cm" || size == "160x200cm") {
        setecoParticipationPrice("15,40€")
      } else if (size == "140x200cm" || size == "140x190cm") {
        setecoParticipationPrice("11,40€")
      } else {
        setecoParticipationPrice("5,70€")
      }
    } else {
      if (size == "180x200cm" || size == "160x200cm") {
        setecoParticipationPrice("15,00€")
      } else if (size == "140x200cm" || size == "140x190cm") {
        setecoParticipationPrice("11,00€")
      } else {
        setecoParticipationPrice("5,50€")
      }
    }
  }

  const ecoPartToolTipText = (type, size) => {
    let ecoPartToolTipPrice = "5,50€"
    if (type === "Pack") {
      if (size == "180x200cm" || size == "160x200cm") {
        ecoPartToolTipPrice = "15,40€"
      } else if (size == "140x200cm" || size == "140x190cm") {
        ecoPartToolTipPrice = "11,40€"
      } else {
        ecoPartToolTipPrice = "5,70€"
      }
    } else {
      if (size == "180x200cm" || size == "160x200cm") {
        ecoPartToolTipPrice = "15,00€"
      } else if (size == "140x200cm" || size == "140x190cm") {
        ecoPartToolTipPrice = "11,00€"
      } else {
        ecoPartToolTipPrice = "5,50€"
      }
    }
    return intl
      .formatMessage({
        id: "eco_paricipation_text",
      })
      .replace("eco_price", ecoPartToolTipPrice)
  }

  const mounted = useRef()
  var searchLocation = useLocation()
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      BottomBarActive()
    }
  }, [selectedVariant, selectOptions])

  useEffect(() => {
    setInitialParamValues()
  }, [searchLocation.search])

  useEffect(() => {
    let price = ""
    if (selectOfferType === "Pack") {
      price = groupDiscountPrice()
    } else {
      price = discountPrice()
    }
    setproductPrice(price)
  }, [selectedVariant])

  useEffect(() => {
    if (intl.locale != "fr") navigate(`/${intl.locale}/shop/`)
  }, [intl.locale])

  return (
    process.env &&
    (process.env.GATSBY_ACTIVE_ENV === "eur" ||
      process.env.GATSBY_ACTIVE_ENV === "general") &&
    intl.locale === "fr" &&
    data && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          jsonLd="matelas"
          productPrice={productPrice}
          productType={selectProductType}
          hreflangPathname={{
            fr: "shop/matelas",
            en: "",
            de: "",
            es: "",
          }}
          noindex={true}
          nofollow={true}
        />

        <MatelasStickyAddToCart
          productType={selectProductType}
          offerType={selectOfferType}
          size={selectOptions["Size"]}
          cart_text={
            !selectOptions["Size"]
              ? intl.formatMessage({
                  id: "button_text_sticky_banner_no_size",
                })
              : data.allDirectusMatelasNewTranslation.nodes[0]
                  .add_to_cart_button
          }
          discountPrice={
            selectOfferType === "Seul" ? discountPrice() : groupDiscountPrice()
          }
          regularPrice={
            selectOfferType === "Seul" ? singlePrice() : groupPrice()
          }
          handleAddTocart={selectedVariant ? handleAddTocart : null}
          active={activeFlag}
          id="sticy-button"
          page="matelas-exclusif"
        />

        <MatelasMiniCart
          data={
            data.allDirectusMatelasNewTranslation.nodes[0]
              .exclusif_mini_cart_section
          }
          price={data.allShopifyProduct.edges}
          product={selectedVariant}
          productType={selectProductType}
          offerType={selectOfferType}
          size={selectOptions["Size"]}
          discountPrice={
            selectOfferType === "Seul" ? discountPrice() : groupDiscountPrice()
          }
          regularPrice={
            selectOfferType === "Seul" ? singlePrice() : groupPrice()
          }
          handleAddTocart={selectedVariant ? handleAddTocart : null}
          show={showMiniCart}
          hide={setshowMiniCart}
          page={"matelas-exclusif"}
        />
        <LyneupContainer
          style={{
            maxWidth: "1300px",
          }}
        >
          <ProductShopSection>
            <LyneShopImageSection>
              <MatelasImageSection
                data={
                  selectProductType === "mousse"
                    ? data.allDirectusMatelasNewTranslation.nodes[0]
                        .mousse_banner_slider.list
                    : data.allDirectusMatelasNewTranslation.nodes[0]
                        .banner_slider.list
                }
                VideoData={
                  selectProductType === "mousse"
                    ? data.allDirectusMatelasNewTranslation.nodes[0]
                        .mousse_video_banner
                    : data.allDirectusMatelasNewTranslation.nodes[0]
                        .video_banner
                }
                sommierData={
                  selectProductType === "mousse"
                    ? data.allDirectusMatelasNewTranslation.nodes[0]
                        .exclusif_mousse_sommier_section
                    : data.allDirectusMatelasNewTranslation.nodes[0]
                        .exclusif_sommier_section
                }
                productType={selectProductType}
                page="matelas-exclusif"
              />
            </LyneShopImageSection>
            <MatelasShopInfoSection page="surmatelas">
              <MatelasShopBox>
                <MatelasProductTopSection
                  title={data.allDirectusMatelasNewTranslation.nodes[0].title}
                  subtitle={
                    data.allDirectusMatelasNewTranslation.nodes[0].subtitle
                  }
                  description={
                    data.allDirectusMatelasNewTranslation.nodes[0].description
                  }
                  page="matelas"
                />
                <MatelasProductType
                  data={
                    data.allDirectusMatelasNewTranslation.nodes[0]
                      .product_section
                  }
                  handleChange={handleProductType}
                  productType={selectProductType}
                  size={selectOptions["Size"]}
                  error={selectProductType ? null : Errors.exist}
                  page="matelas-exclusif"
                />
                <LyneImageSlider
                  data={
                    selectProductType === "mousse"
                      ? data.allDirectusMatelasNewTranslation.nodes[0]
                          .mousse_banner_slider.list
                      : data.allDirectusMatelasNewTranslation.nodes[0]
                          .banner_slider.list
                  }
                />
                <DimensionText
                  dangerouslySetInnerHTML={{
                    __html:
                      data.allDirectusMatelasNewTranslation.nodes[0]
                        .dimensions_text,
                  }}
                />

                <Select
                  id="pa_taille_classique"
                  name="Size"
                  pageName="matelas"
                  onChange={handleChangeSelectOptions}
                  value={selectOptions["Size"]}
                  error={!selectOptions["Size"] && Errors.exist}
                  style={{ width: "100%" }}
                >
                  {/* <option value="">
                    {intl.formatMessage({
                      id: "my_percko_matelas_size_text",
                    })}
                  </option> */}
                  {data.allDirectusMatelasNewTranslation.nodes[0].product_size.list.map(
                    (item, index) => (
                      <option
                        value={item.value}
                        key={`Size-${index.toString()}`}
                      >
                        {item.size}
                      </option>
                    )
                  )}
                </Select>
              </MatelasShopBox>
              <MatelasShopBox>
                <MatelasComboWrapper>
                  <LyneComboBox onClick={() => handleOfferType("Seul")}>
                    <LyneComboBoxTagWrapper>
                      <ExclusifComboBoxTag lang={intl.locale}>
                        Offre Exclusive
                      </ExclusifComboBoxTag>
                    </LyneComboBoxTagWrapper>
                    <ExclusifComboBoxTitle
                      dangerouslySetInnerHTML={{
                        __html:
                          selectProductType === "mousse"
                            ? "<strong>1 matelas mousse essentiel <span>-30%</span></strong>"
                            : "<strong>1 matelas hybride premium <span>-30%</span></strong>",
                      }}
                    />
                    <LyneComboBottomSection>
                      <LyneComboLeftSection>
                        <MatelasComboBoxImage
                          className="lazyload"
                          data-src="https://static.percko.com/uploads/8dd8f7f6-0a70-4bb1-9728-f29c0f0c1f20.jpg"
                        />
                        <ExclusifProductStrikePrice>
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${Number(
                            singleMattressVariant
                              ? singleMattressVariant.price
                              : singleMattressData?.priceRangeV2
                                  ?.maxVariantPrice.amount
                          )}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ExclusifProductStrikePrice>
                        <ExclusifProductDiscountPrice>
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${discountPrice()}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                          <EcoPartToolTip
                            data-tip={ecoPartToolTipText(
                              "Seul",
                              selectOptions["Size"]
                            )}
                            data-html={true}
                            data-for="matelas_ecoprice1"
                          >
                            i
                          </EcoPartToolTip>
                        </ExclusifProductDiscountPrice>
                      </LyneComboLeftSection>
                      <LyneCheckImage
                        src={
                          selectOfferType === "Seul"
                            ? "https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                            : "https://static.percko.com/uploads/cc8a1cf0-e49a-4790-a3a7-8bc21ba3f443.svg"
                        }
                      />
                    </LyneComboBottomSection>
                  </LyneComboBox>
                  <LyneComboBox
                    border="0px"
                    onClick={() => handleOfferType("Pack")}
                  >
                    <LyneComboBoxTagWrapper>
                      <ExclusifComboBoxTag lang={intl.locale}>
                        Offre Exclusive
                      </ExclusifComboBoxTag>
                    </LyneComboBoxTagWrapper>
                    {selectedVariant &&
                    selectOptions &&
                    selectOptions["Size"] != "80x200cm" &&
                    selectOptions["Size"] != "90x190cm" &&
                    selectOptions["Size"] != "90x200cm" ? (
                      <ExclusifComboBoxTitle
                        dangerouslySetInnerHTML={{
                          __html:
                            selectProductType === "mousse"
                              ? "<strong>1 matelas mousse essentiel + 2 oreillers universels <span>-35%</span></strong> sur le pack"
                              : "<strong>1 matelas hybride premium + 2 oreillers universels <span>-35%</span></strong> sur le pack",
                        }}
                      />
                    ) : (
                      <ExclusifComboBoxTitle
                        dangerouslySetInnerHTML={{
                          __html:
                            selectProductType === "mousse"
                              ? "<strong>1 matelas mousse essentiel + 1 oreiller universel <span>-35%</span></strong> sur le pack"
                              : "<strong>1 matelas hybride premium + 1 oreiller universel <span>-35%</span></strong> sur le pack",
                        }}
                      />
                    )}
                    <LyneComboBottomSection>
                      <LyneComboLeftSection>
                        {selectedVariant &&
                        selectOptions &&
                        selectOptions["Size"] != "80x200cm" &&
                        selectOptions["Size"] != "90x190cm" &&
                        selectOptions["Size"] != "90x200cm" ? (
                          <MatelasComboBoxImage src="https://static.percko.com/uploads/aa0c7a01-ea22-4200-ac3a-73be7e9125d1.jpg" />
                        ) : (
                          <MatelasComboBoxImage src="https://static.percko.com/uploads/061b5af6-1561-4e85-ba3d-a936bf59fb75.jpg" />
                        )}
                        <ExclusifProductStrikePrice>
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${groupPrice()}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                        </ExclusifProductStrikePrice>
                        <ExclusifProductDiscountPrice>
                          {`${
                            process.env.GATSBY_CURRENCY_TYPE !== "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }${groupDiscountPrice()}${
                            process.env.GATSBY_CURRENCY_TYPE === "eur"
                              ? process.env.GATSBY_CURRENCY
                              : ""
                          }`}
                          <EcoPartToolTip
                            data-tip={ecoPartToolTipText(
                              "Pack",
                              selectOptions["Size"]
                            )}
                            data-html={true}
                            data-for="matelas_ecoprice2"
                          >
                            i
                          </EcoPartToolTip>
                        </ExclusifProductDiscountPrice>
                      </LyneComboLeftSection>
                      <LyneCheckImage
                        src={
                          selectOfferType === "Pack"
                            ? "https://static.percko.com/uploads/fd189ad9-7d89-4e1c-b414-751a362b4668.svg"
                            : "https://static.percko.com/uploads/cc8a1cf0-e49a-4790-a3a7-8bc21ba3f443.svg"
                        }
                      />
                    </LyneComboBottomSection>
                  </LyneComboBox>
                  <ReactTooltip
                    id="matelas_ecoprice1"
                    html={true}
                    place="top"
                  />
                  <ReactTooltip
                    id="matelas_ecoprice2"
                    html={true}
                    place="top"
                  />
                </MatelasComboWrapper>
              </MatelasShopBox>
              <MatelasShopBox>
                <div style={{ margin: "-10px 0px 10px 0px" }}>
                  {productPrice && selectedVariant && (
                    <MatelasAlmaSection
                      price={
                        selectOfferType === "Seul"
                          ? discountPrice()
                          : groupDiscountPrice()
                      }
                    />
                  )}
                </div>
                <DimensionText
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "lyne_product_select_text",
                    }),
                  }}
                />

                {selectOptions["Size"] && !Errors.exist && (
                  <LyneSizeSelectedText
                    dangerouslySetInnerHTML={{
                      __html:
                        selectProductType === "mousse" &&
                        selectOptions["Size"] != "80x200cm" &&
                        selectOptions["Size"] != "90x190cm" &&
                        selectOptions["Size"] != "90x200cm" &&
                        selectOfferType === "Pack"
                          ? `1 matelas mousse essentiel ${selectOptions["Size"]} + 2 oreillers universels`
                          : selectProductType === "mousse" &&
                            selectOfferType === "Pack"
                          ? `1 matelas mousse essentiel ${selectOptions["Size"]} + 1 oreiller universel`
                          : selectProductType === "mousse" &&
                            selectOfferType === "Seul"
                          ? `1 matelas mousse essentiel ${selectOptions["Size"]}`
                          : selectOptions["Size"] != "80x200cm" &&
                            selectOptions["Size"] != "90x190cm" &&
                            selectOptions["Size"] != "90x200cm" &&
                            selectOfferType === "Pack"
                          ? `1 matelas hybride premium ${selectOptions["Size"]} + 2 oreillers universels`
                          : selectOfferType === "Pack"
                          ? `1 matelas hybride premium ${selectOptions["Size"]} + 1 oreiller universel`
                          : `1 matelas hybride premium ${selectOptions["Size"]}`,
                    }}
                    style={{ margin: "0px 0px 20px 0px" }}
                  />
                )}

                <MatelasButtonWrapper>
                  <AddToCartWrapper>
                    <ExclusifAddToCart
                      id="add_to_cart"
                      loading={storeContext.adding ? "adding" : ""}
                      onClick={
                        selectOptions["Size"] ? handleAddTocart : () => {}
                      }
                      disabled={selectOptions["Size"] ? false : true}
                    >
                      {
                        data.allDirectusMatelasNewTranslation.nodes[0]
                          .add_to_cart_button
                      }
                    </ExclusifAddToCart>
                  </AddToCartWrapper>
                </MatelasButtonWrapper>

                {selectOptions["Size"] && (
                  <ProductEstimateShippingBox page="matelas">
                    <ProductEstimateShippingIcon src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg" />
                    <ProductEstimateShippingText>
                      {cartEstimateDeliveryText(
                        "matelas",
                        data.allDirectusCommonTranslation.nodes[0].months_text,
                        intl.locale,
                        selectOptions["Size"]
                      )}
                    </ProductEstimateShippingText>
                  </ProductEstimateShippingBox>
                )}
                {/* {ecoParticipationPrice && (
                  <EcoParticipationText
                    dangerouslySetInnerHTML={{
                      __html: intl
                        .formatMessage({
                          id: "eco_paricipation_text",
                        })
                        .replace("eco_price", ecoParticipationPrice),
                    }}
                  />
                )} */}

                {data.allDirectusMatelasNewTranslation.nodes[0]
                  .review_info_text && (
                  <EcoParticipationText
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusMatelasNewTranslation.nodes[0]
                          .review_info_text,
                    }}
                    style={{ fontSize: 14 }}
                  />
                )}

                {data.allDirectusMatelasNewTranslation.nodes[0]
                  .exclusif_sommier_section &&
                  data.allDirectusMatelasNewTranslation.nodes[0]
                    .exclusif_mousse_sommier_section && (
                    <MatelasSommierSection
                      data={
                        selectProductType === "mousse"
                          ? data.allDirectusMatelasNewTranslation.nodes[0]
                              .exclusif_mousse_sommier_section
                          : data.allDirectusMatelasNewTranslation.nodes[0]
                              .exclusif_sommier_section
                      }
                      mobile={true}
                      productType={selectProductType}
                      page="matelas-exclusif"
                    />
                  )}

                {data.allDirectusMatelasNewTranslation.nodes[0]
                  .testimonial_section && (
                  <LyneTestimonialSection
                    data={
                      data.allDirectusMatelasNewTranslation.nodes[0]
                        .testimonial_section
                    }
                    page="surmatelas"
                  />
                )}
              </MatelasShopBox>
            </MatelasShopInfoSection>
          </ProductShopSection>
        </LyneupContainer>

        {data.allDirectusMatelasNewTranslation.nodes[0].page_section_list && (
          <LyneupPageSectionList
            data={
              data.allDirectusMatelasNewTranslation.nodes[0].page_section_list
            }
            page="matelas"
          />
        )}

        {data.allDirectusMatelasNewTranslation.nodes[0].soulagment_section && (
          <SoulagementSection
            data={
              data.allDirectusMatelasNewTranslation.nodes[0].soulagment_section
            }
            id="soulager_dos_section"
          />
        )}

        {data.allDirectusMatelasNewTranslation.nodes[0].soulager_section && (
          <SoulagerSection
            data={
              data.allDirectusMatelasNewTranslation.nodes[0].soulager_section
            }
            id="soul_section"
          />
        )}

        {data.allDirectusMatelasNewTranslation.nodes[0]
          .confort_optimal_section && (
          <ConfortSection
            data={
              selectProductType === "mousse"
                ? data.allDirectusMatelasNewTranslation.nodes[0]
                    .mousse_confort_optimal_section
                : data.allDirectusMatelasNewTranslation.nodes[0]
                    .confort_optimal_section
            }
            id="confort_section"
          />
        )}

        {data.allDirectusMatelasNewTranslation.nodes[0]
          .produit_sain_section && (
          <ProductSainSection
            data={
              data.allDirectusMatelasNewTranslation.nodes[0]
                .produit_sain_section
            }
            id="product_sain_section"
            page="matelas"
          />
        )}

        {data.allDirectusMatelasNewTranslation.nodes[0].composition_section && (
          <CompositionSection
            data={
              selectProductType === "mousse"
                ? data.allDirectusMatelasNewTranslation.nodes[0]
                    .mousse_composition_section
                : data.allDirectusMatelasNewTranslation.nodes[0]
                    .composition_section
            }
            productType={selectProductType}
            id="composition_section"
          />
        )}

        {data.allDirectusMatelasNewTranslation.nodes[0].composition_section && (
          <MatelasComparisonSection
            data={
              selectProductType === "mousse"
                ? data.allDirectusMatelasNewTranslation.nodes[0]
                    .mousse_composition_section
                : data.allDirectusMatelasNewTranslation.nodes[0]
                    .composition_section
            }
            productType={selectProductType}
            handleChange={handleProductType}
            id="comparison_section"
          />
        )}

        {data.allDirectusReviewTranslation.nodes && (
          <TrustPilotReviewSection
            data={data.allDirectusReviewTranslation.nodes}
            id={"satisfaction_client"}
          />
        )}

        {data.allDirectusMatelasNewTranslation.nodes[0].review_section && (
          <MatelasReviewSection
            data={data.allDirectusMatelasNewTranslation.nodes[0].review_section}
            id="review_section"
            page="matelas"
          />
        )}

        {data.allDirectusMatelasNewTranslation.nodes[0].cross_section && (
          <CrossProductSection
            data={data.allDirectusMatelasNewTranslation.nodes[0].cross_section}
            price={data.allShopifyProduct.edges}
            page="matelas"
          />
        )}

        {data.allDirectusMatelasNewTranslation.nodes[0].faq_section && (
          <ProductFaqSection
            data={
              selectProductType === "mousse"
                ? data.allDirectusMatelasNewTranslation.nodes[0]
                    .mousse_faq_section
                : data.allDirectusMatelasNewTranslation.nodes[0].faq_section
            }
            pageName="matelas"
            id="faq_section"
          />
        )}

        {data.allDirectusCommonTranslation.nodes.length > 0 && (
          <Testimonials
            data={data.allDirectusCommonTranslation.nodes[0].testimonials.list}
          />
        )}

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusMatelasNewTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        video_banner {
          video_placeholder
          video_url
        }
        mousse_video_banner {
          video_placeholder
          video_url
        }
        banner_slider {
          list {
            img
            alt
          }
        }
        mousse_banner_slider {
          list {
            img
            alt
          }
        }
        title
        subtitle
        description
        product_section {
          title
          list {
            image
            alt
            title
            tag
            type
          }
        }
        dimensions_text
        product_size {
          list {
            size
            value
          }
        }
        add_to_cart_button
        review_info_text
        testimonial_section {
          title
          subtitle
          list {
            image
            text
            mobile_text
            popup_text
          }
        }
        page_section_list {
          list {
            title
            id
          }
        }
        soulagment_section {
          section_title
          section_mobile_title
          section_subtitle
          section_mobile_subtitle
          list {
            image
            alt
            title
            subtitle
            icon_image
            author_name
            designation
            text
          }
        }
        soulager_section {
          image
          alt
          title
          subtitle
          icon_image
          author_name
          designation
          text
        }
        confort_optimal_section {
          section_title
          section_mobile_title
          section_subtitle
          section_mobile_subtitle
          top_list {
            image
            alt
            text
            mobile_text
          }
          bottom_list {
            image
            alt
            title
            subtitle
            list {
              image
              alt
              text
              popup_text
            }
          }
        }
        mousse_confort_optimal_section {
          section_title
          section_mobile_title
          section_subtitle
          section_mobile_subtitle
          top_list {
            image
            alt
            text
            mobile_text
          }
          bottom_list {
            image
            alt
            title
            subtitle
            list {
              image
              alt
              text
              popup_text
            }
          }
        }
        produit_sain_section {
          section_title
          section_mobile_title
          section_subtitle
          section_mobile_subtitle
          bottom_text
          mobile_bottom_text
          list {
            image
            alt
            top_image
            title
            text
            mobile_text
          }
        }
        composition_section {
          section_title
          image
          alt
          list {
            title
            text
          }
        }
        mousse_composition_section {
          section_title
          image
          alt
          list {
            title
            text
          }
        }
        satisfaction_section {
          title
          subtitle
          mobile_subtitle
        }
        review_section {
          title
          text
          review_list {
            image
            alt
            quote_image
            name
            designation
            company
            city
            text
          }
        }
        faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              alt
              text
            }
            open
          }
        }
        mousse_faq_section {
          title
          subtitle
          list {
            question
            answer {
              image
              mobile_image
              alt
              text
            }
            open
          }
        }
        cross_section {
          section_title
          name
          image
          alt
          tag
          title
          subtitle
          url
          button_text
          product_list {
            name
            image
            alt
            logo
            logo_alt
            title
            subtitle
            url
          }
        }
        exclusif_sommier_section {
          title
          text
          offer_title
          offer_text
          image
          image_alt
          button_text
          button_url
        }
        exclusif_mousse_sommier_section {
          title
          text
          offer_title
          offer_text
          image
          image_alt
          button_text
          button_url
        }
        exclusif_mini_cart_section {
          title
          cart_button_text
          cart_button_url
          offer_section_title
          offer_top_text
          offer_title
          offer_title_url
          offer_text
          offer_image
          offer_image_alt
          offer_bottom_text
          product_list {
            name
            image
            alt
            title
            text
            top_text
            button_text
            page_text
            url
          }
        }
      }
    }
    allShopifyProduct(sort: { order: ASC, fields: handle }) {
      edges {
        node {
          id: storefrontId
          handle
          createdAt
          updatedAt
          descriptionHtml
          description
          productType
          title
          vendor
          publishedAt
          options {
            id
            name
            values
          }
          variants {
            id: storefrontId
            title
            price
            weight
            sku
            image {
              id
              src: originalSrc
              #   altText
            }
            selectedOptions {
              name
              value
            }
            price
          }
          images {
            src: originalSrc
            id
          }
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
    allDirectusReviewTranslation(
      filter: {
        review: { product: { eq: "matelas" } }
        language: { eq: $lang }
      }
    ) {
      nodes {
        author_name
        review_text
        place_and_date
        review {
          stars
          name
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
        months_text {
          month
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "matelas_new" } }
      }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default withStoreContext(injectIntl(matelas))
